import React from 'react';
import {
  WeeklyDatePicker as WeeklyDatePickerComponent,
  WeeklyDatePickerProps as WeeklyDatePickerComponentProps,
} from 'wix-ui-tpa';
import {
  getLocalDateTimeEndOfDay,
  getLocalDateTimeStartOfDay,
} from '../../../../../../utils/dateAndTime/dateAndTime';
import { DatePickerProps, DatePickerDataHooks } from '../constants';
import { useCalendarActions } from '../../../../Hooks/useCalendarActions';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useDatePicker } from '../useDatePicker';
import { classes as datePickerClasses } from '../DatePicker.st.css';
import { classes, st } from './WeeklyDatePicker.st.css';

const WeeklyDatePicker: React.FC<DatePickerProps> = ({ viewModel }) => {
  const {
    onDateChange,
    dateIndications,
    selectedDate,
    today,
    accessibility: { nextAriaLabel, prevAriaLabel },
    firstDayOfTheWeek,
    locale,
  } = useDatePicker(viewModel);

  const { onRangeSet } = useCalendarActions();
  const { isMobile } = useEnvironment();

  const onWeekChange = ({
    startOfWeek,
    endOfWeek,
  }: {
    startOfWeek: Date | string;
    endOfWeek: Date | string;
  }) => {
    const fromAsLocalDateTime = getLocalDateTimeStartOfDay(startOfWeek as Date);
    const toAsLocalDateTime = getLocalDateTimeEndOfDay(endOfWeek as Date);
    onRangeSet({ fromAsLocalDateTime, toAsLocalDateTime });
  };

  return (
    <WeeklyDatePickerComponent
      key={selectedDate.toString()}
      data-hook={DatePickerDataHooks.WEEKLY_DATE_PICKER}
      className={st(classes.root, { isMobile }, datePickerClasses.root)}
      value={selectedDate}
      today={today}
      onChange={onDateChange}
      onDisplayedWeekChange={onWeekChange}
      dateIndication={dateIndications}
      firstDayOfWeek={firstDayOfTheWeek}
      rightArrowAriaLabel={nextAriaLabel}
      leftArrowAriaLabel={prevAriaLabel}
      removeOuterPadding
      locale={locale as WeeklyDatePickerComponentProps['locale']}
    />
  );
};

export default WeeklyDatePicker;
