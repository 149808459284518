import React from 'react';
import { DailyAgendaWeeklyPickerLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
// import TimePicker from '../TimePicker/TimePicker';
// import DatePicker from '../DatePicker/DatePicker';
// import WeeklyDatePicker from '../DatePicker/WeeklyDatePicker/WeeklyDatePicker';
import { DataHooks } from './constants';
import { classes } from '../DailyLayout.st.css';

export interface DailyAgendaWeeklyPickerLayoutProps {
  viewModel: DailyAgendaWeeklyPickerLayoutViewModel;
  errorNotificationText: string;
}

const DailyAgendaWeeklyPickerLayout: React.FC<
  DailyAgendaWeeklyPickerLayoutProps
> = ({
  // viewModel: { datePickerViewModel, timePicker },
  errorNotificationText,
}) => {
  // const { status, formattedSelectedDate, accessibility } = timePicker;

  return (
    <div data-hook={DataHooks.Layout}>
      <ErrorNotification errorText={errorNotificationText} />
      <div className={classes.root}>
        {/* <WeeklyDatePicker*/}
        {/*  viewModel={datePickerViewModel}*/}
        {/*/ >*/}
        {/* <TimePicker*/}
        {/*  viewModel={{*/}
        {/*    formattedSelectedDate,*/}
        {/*    status,*/}
        {/*    accessibility,*/}
        {/*  }}*/}
        {/*  availableSlotsComponent={*/}
        {/*    <div>*/}
        {/*      <div>Nothing to show yet</div>*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*  noAvailableSlotsComponent={<div>TODO like on desktop</div>}*/}
        {/*/ >*/}
      </div>
    </div>
  );
};

export default DailyAgendaWeeklyPickerLayout;
