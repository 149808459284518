import {
  createSettingsParams,
  createSettingsParam,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';
import {
  AlignmentOptions,
  LayoutOptions,
  SlotsAvailability,
  SourceOptions,
} from '../../types/types';
import { InitializeCalendarDateOptions } from '../../utils/bi/consts';

export type ISettingsParams = {
  columnAlignment: SettingsParamType.String;
  textAlignment: AlignmentOptions;
  slotAlignment: AlignmentOptions;
  calendarLayout: LayoutOptions;
  dateAndTimeSectionHeader: SettingsParamType.Text;
  bookingDetailsSectionHeader: SettingsParamType.Text;
  headerTitleVisibility: SettingsParamType.Boolean;
  headerSubtitleVisibility: SettingsParamType.Boolean;
  headerFiltersVisibility: SettingsParamType.Boolean;
  headerServiceFilterVisibility: SettingsParamType.Boolean;
  headerStaffFilterVisibility: SettingsParamType.Boolean;
  headerLocationFilterVisibility: SettingsParamType.Boolean;
  headerSubtitleSource: SourceOptions;
  headerTitle: SettingsParamType.Text;
  headerSubtitle: SettingsParamType.Text;
  noSessionsOffered: SettingsParamType.Text;
  noUpcomingTimeSlots: SettingsParamType.Text;
  goToNextAvailableDate: SettingsParamType.Text;
  fullyBookedDateNotification: SettingsParamType.Text;
  loadMoreTimeSlots: SettingsParamType.Text;
  limitTimeSlotsDisplay: SettingsParamType.Boolean;
  maxTimeSlotsDisplayedPerDay: SettingsParamType.Number;
  videoConferenceBadgeVisibility: SettingsParamType.Boolean;
  videoConferenceBadge: SettingsParamType.Text;
  preferencesTitle: SettingsParamType.Text;
  serviceLabel: SettingsParamType.Text;
  staffMemberLabel: SettingsParamType.Text;
  locationLabel: SettingsParamType.Text;
  durationLabel: SettingsParamType.Text;
  bookingDetailsPricingPlanText: SettingsParamType.Text;
  bookingDetailsClearText: SettingsParamType.Text;
  nextButton: SettingsParamType.Text;
  pendingApprovalButton: SettingsParamType.Text;
  joinWaitlistButton: SettingsParamType.Text;
  rescheduleButton: SettingsParamType.Text;
  slotsAvailability: SlotsAvailability;
  initializeCalendarDate: InitializeCalendarDateOptions;
  waitlistIndication: SettingsParamType.Text;
  buttonsFullWidth: SettingsParamType.Boolean;
  slotLocationVisibility: SettingsParamType.Boolean;
  slotDurationVisibility: SettingsParamType.Boolean;
  slotStaffMemberVisibility: SettingsParamType.Boolean;
  slotPriceVisibility: SettingsParamType.Boolean;
  spotsLeftVisibility: SettingsParamType.Boolean;
  slotRegistrationStatusVisibility: SettingsParamType.Boolean;
  spotsLeftFormat: SettingsParamType.Text;
};

const calendarLayout = createSettingsParam('calendarLayout', {
  getDefaultValue: () => LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER,
  inheritDesktop: false,
});

const headerFiltersVisibility = createSettingsParam('headerFiltersVisibility', {
  type: SettingsParamType.Boolean,
  getDefaultValue: () => true,
});

export default createSettingsParams<ISettingsParams>({
  columnAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  textAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  slotAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  calendarLayout: calendarLayout as any,
  dateAndTimeSectionHeader: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  bookingDetailsSectionHeader: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  headerTitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerSubtitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerFiltersVisibility,
  headerServiceFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerStaffFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }) =>
      getSettingParamValue(headerFiltersVisibility) ?? true,
  },
  headerLocationFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }) =>
      getSettingParamValue(headerFiltersVisibility) ?? true,
  },
  headerSubtitleSource: {
    getDefaultValue: () => SourceOptions.CUSTOM,
  },
  headerTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  headerSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  noSessionsOffered: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  noUpcomingTimeSlots: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  goToNextAvailableDate: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  fullyBookedDateNotification: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  loadMoreTimeSlots: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  limitTimeSlotsDisplay: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  maxTimeSlotsDisplayedPerDay: {
    type: SettingsParamType.Number,
    getDefaultValue: ({ getSettingParamValue }) => {
      const layout =
        getSettingParamValue(calendarLayout) ||
        LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;
      return layout === LayoutOptions.WEEKLY_TIME_SLOTS ? 5 : 10;
    },
  },
  preferencesTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  serviceLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  staffMemberLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  locationLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  durationLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  bookingDetailsPricingPlanText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pricing-plan.title'),
  },
  bookingDetailsClearText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.clear.title'),
  },
  nextButton: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  rescheduleButton: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  pendingApprovalButton: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  joinWaitlistButton: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  videoConferenceBadgeVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  videoConferenceBadge: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  slotsAvailability: {
    getDefaultValue: () => SlotsAvailability.ALL,
  },
  initializeCalendarDate: {
    getDefaultValue: () => InitializeCalendarDateOptions.TODAY,
  },
  waitlistIndication: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  buttonsFullWidth: {
    type: SettingsParamType.Boolean,
    inheritDesktop: false,
    getDefaultValue: () => true,
  },
  slotLocationVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  slotRegistrationStatusVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  spotsLeftVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  slotPriceVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  slotStaffMemberVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  slotDurationVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  spotsLeftFormat: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
});
